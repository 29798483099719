<template>
  <div class="error-message" v-if="v.$error">
    <small v-if="v.requiredIf === false ||
                 v.required === false">이건 꼭 입력해야 해요!</small>
    <small v-if="v.maxLength === false">너무 길게 입력했어요!</small>
  </div>
</template>

<script>
  import { defaultV } from '~/admin/validators'

  export default {
    props: {
      v: defaultV
    }
  }
</script>

<style scoped lang="scss">
  @import '../stylesheets/admin/variables';

  .error-message {
    margin-top: 0.25rem;
    color: $red;
  }
</style>