<template>
  <div class="form-group">
    <label v-if="label">{{ label }}</label>
    <div :class="{ error: v.$error, 'animated shake': v.$error }">
      <textarea class="form-control" :rows="rows"
                :placeholder="placeholder" :value="modelValue"
                @input="onInput($event.target.value)"
                @keydown.shift.enter.prevent="onEnter"
      ></textarea>
    </div>
    <validation-message :v="v"></validation-message>
  </div>
</template>

<script>
  import { defaultV } from '~/admin/validators'

  export default {

    props: {
      label: String,
      modelValue: [String],
      rows: [Number],
      placeholder: { type: String, default: '' },
      v: defaultV
    },
    data() {
      return {
      }
    },
    methods: {
      onInput(value) {
        this.v.$touch();
        this.$emit('update:modelValue', value);
      },
      onEnter(value) {
        this.$emit('enter', this.modelValue);
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '../stylesheets/admin/variables';

  .error {
    textarea {
      border: 1px solid $red;
      box-shadow: 0 0 0 0.2rem rgba($red, .25);
    }
  }
</style>