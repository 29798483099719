import {createApp} from 'vue';
import {createI18n} from 'vue-i18n';
import locales from './locale'
import Promise from 'promise-polyfill';
import TextInput from "~/components/text_input.vue";
import TextAreaInput from "~/components/text_area_input.vue";
import ValidationMessage from "~/components/validation_message.vue";

function isObject (value) {
  return value === Object(value)
}

function isArray (value) {
  return Array.isArray(value)
}

function isFile (value) {
  return value instanceof File
}

function humanNumber(number) {
  if (number) {
    let parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  } else {
    return null;
  }
}

function humanDatetime(datetime) {
  const date = new Date(datetime);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
}

export default {

  mountComponent(selector, rootComponent, store) {
    const elements = document.querySelectorAll(selector);
    elements.forEach(function (el) {
      const props = JSON.parse(el.getAttribute('data'));

      const i18n = createI18n({
        locale: window.languageCode,
        fallbackLocale: 'ko',
        messages: locales
      });

      const app = createApp(rootComponent, props);
      app.component('text-input', TextInput);
      app.component('text-area-input', TextAreaInput);
      app.component('validation-message', ValidationMessage);

      app.use(i18n);
      if (store) {
        app.use(store);
      }

      app.mount(selector);
    });
  },
  setupAxios(axios) {
    axios.default.defaults.headers.common['Accept'] = 'application/json';
    axios.default.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name=csrf-token]").content;

    axios.default.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if (_.get(error, 'response.status') === 401) {
        let redirect_url = _.get(error, 'response.data.redirect_to');

        if (redirect_url) {
          window.location.replace(redirect_url);
        }
      }

      return Promise.reject(error);
    });
  },
  objectToFormData(obj, fd, pre) {
    fd = fd || new FormData();
    let that = this;

    Object.keys(obj).forEach(function (prop) {
      let key = pre ? (pre + '[' + prop + ']') : prop;

      if (isObject(obj[prop]) && !isArray(obj[prop]) && !isFile(obj[prop])) {
        that.objectToFormData(obj[prop], fd, key)
      } else if (isArray(obj[prop])) {
        obj[prop].forEach(function (value, index) {
          if (isObject(value) && !isFile(value)) {
            let objectKey = key + '[' + index + ']';
            that.objectToFormData(value, fd, objectKey)
          } else {
            let arrayKey = key + '[]';
            fd.append(arrayKey, value)
          }
        })
      } else {
        fd.append(key, obj[prop])
      }
    });

    return fd
  },
  queryParams() {
    return document.location.search.replace(/(^\?)/,'').split("&").map(function(n){return n = n.split("="),this[n[0]] = decodeURIComponent(n[1]),this}.bind({}))[0];
  },
}

const app = createApp({});

app.config.globalProperties.$filters = {
  human_date(date) {
    let d = new Date(date);
    return `${d.getFullYear()}.${d.getMonth() + 1}.${d.getDate()}`;
  },
  human_number(number) {
    return humanNumber(number);
  },
  human_datetime(datetime) {
    return humanDatetime(datetime);
  }
};


